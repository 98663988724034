import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/stickyHeader';
import olcBanner from '../assets/olc_banner_no_logo.png';
import iconAsk from '../assets/ask-the-expert.png';
import iconBook from '../assets/icon.png';
import iconEnter from '../assets/enter.png';
import questionApi from '../services/questionActions';
import { message } from 'antd';
import badgeIcon from '../images/badgeIcon.png';

import module0 from '../images/latest/module-0.png';
import module1 from '../images/latest/module-1.png';
import module2 from '../images/latest/module-2.png';
import module3 from '../images/latest/module-3.png';
import module4 from '../images/latest/module-4.png';
import module5 from '../images/latest/module-5.png';
import module6 from '../images/latest/module-6.png';
import module7 from '../images/latest/module-7.png';
import module8 from '../images/latest/module-8.png';
import CertificatePopup from '../components/CertificatePopup';
import { useSelector } from 'react-redux';

import TakeSurveyModal from '../components/TakeSurveyModal';
const moment = require('moment');

const moduleImages = [
  module0,
  module1,
  module2,
  module3,
  module4,
  module5,
  module6,
  module7,
  module8,
];

const Modules = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const [modules, setModules] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [completedModule, setCompletedModule] = useState(null);
  const [m7Doctors, setm7Doctors] = useState([]);
  const [m8Doctors, setm8Doctors] = useState([]);
  const [isModalSurveyPreOpen, setIsModalSurveyPreOpen] = useState(false);
  const [isModalSurveyOpen, setIsModalSurveyOpen] = useState(false);
  const [postSurveyNeedToComplete, setPostSurveyNeedToComplete] =
    useState(false);

  const [showm7, setShowm7] = useState(false);
  const [showm8, setShowm8] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showMoreHander = (idx, type) => {
    if (idx == 7) {
      if (type === 'open') {
        setShowm7(true);
      } else {
        setShowm7(false);
      }
    } else if (idx == 8) {
      if (type === 'open') {
        setShowm8(true);
      } else {
        setShowm8(false);
      }
    }
  };

  const renderDoctorInfo = (idx) => {
    let doctor = {};
    if (Number(idx) === 2) {
      doctor = speakers.find((e) => e.id === '1');
    } else if (Number(idx) === 0 || Number(idx) === 1) {
      doctor = speakers.find((e) => e.id === '2');
    } else if (Number(idx) === 3 || Number(idx) === 4) {
      doctor = speakers.find((e) => e.id === '3');
    } else if (Number(idx) === 5 || Number(idx) === 6) {
      doctor = speakers.find((e) => e.id === '4');
    }

    return (
      <>
        <div className="instructor">
          {doctor.title} {doctor.name}
        </div>
        <div className="position">{doctor.speciality}</div>
        <div className="position">{doctor.sector}</div>{' '}
      </>
    );
  };

  // console.log('postSurveyEligible', postSurveyEligible);

  useEffect(() => {
    const getModules = async () => {
      try {
        const res = await questionApi.getModules();
        if (res) {
          // console.log('res', res);
          setModules(res.data.data);
          setSpeakers(res.data.speakers);

          const doctors7 = res.data.speakers.filter((e) => e.id > 5);
          const doctors8 = res.data.speakers.filter(
            (e) => e.id > 4 && e.id < 7
          );

          setm7Doctors(doctors7);
          setm8Doctors(doctors8);
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    getModules();
  }, []);

  // console.log('user', user);

  useEffect(() => {
    const getCompletedModules = async () => {
      try {
        const res = await questionApi.getModuleCompletion();
        const postSurveyData = await fetch(
          `/api/survey/check?userId=${user.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const surveyData = await postSurveyData.json();

        if (res) {
          const completedModules = res.data.data;
          // console.log('res.data', res.data);
          const allCompleted = res.data.allCompleted;

          const preSurveyCompleted = surveyData?.data?.find(
            (each) => each.type === 'pre'
          );
          const postSurveyCompleted = surveyData?.data?.find(
            (each) => each.type === 'post'
          );

          // survey data
          if (surveyData.data) {
            if (!preSurveyCompleted) {
              setIsModalSurveyPreOpen(true);
            }
          }
          // console.log('allCompleted', allCompleted);
          if (allCompleted && preSurveyCompleted && !postSurveyCompleted) {
            setPostSurveyNeedToComplete(true);
          }

          if (allCompleted) {
            const getComDate = completedModules.sort((a, b) => {
              const date1 = moment(a.assessment_completion);
              const date2 = moment(b.assessment_completion);

              if (date1.isBefore(date2)) {
                return 1;
              } else if (date1.isAfter(date2)) {
                return -1;
              } else {
                return 0;
              }
            });

            if (getComDate[0]) {
              if (getComDate[0]?.custom_modules_completion) {
                const convertedDate = moment(
                  getComDate[0].custom_modules_completion
                ).format('DD/MM/YYYY');
                setCompletedModule(convertedDate);
              } else {
                const convertedDate = moment(
                  getComDate[0].assessment_completion
                ).format('DD/MM/YYYY');
                setCompletedModule(convertedDate);
              }
            }
          }
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    if (user) {
      getCompletedModules();
    }
  }, [user]);

  return (
    <div className="main-container">
      <Header navigate={navigate} />

      <TakeSurveyModal
        isModalSurveyOpen={isModalSurveyOpen}
        navigate={navigate}
        description={
          <div>
            Kindly complete the Post-training Survey to
            <div>download your Advocate certificate.</div>
          </div>
        }
        action={() => navigate('/survey/post/one')}
      />

      <TakeSurveyModal
        isModalSurveyOpen={isModalSurveyPreOpen}
        navigate={navigate}
        description={
          <div>
            Kindly complete the Pre-training Survey to
            <div>proceed to the learning modules.</div>
          </div>
        }
        action={() => navigate('/survey/pre/one')}
      />

      <CertificatePopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        completedModule={completedModule}
      />

      <div className="text-center">
        <img className="olc-banner" src={olcBanner} alt="banner" />

        {completedModule ? (
          <div className="text-white mt-4 ">
            <img src={badgeIcon} style={{ height: '50px' }} alt="badgeIcon" />
            <div style={{ fontWeight: '700', fontSize: '20px' }}>
              Great Job!
            </div>
            <div className="py-2">
              <div>You have already completed all your modules, find out</div>
              <div>what you can do with your certification!</div>
            </div>

            <div>
              <button
                className="custom-button mt-3 fw-bold"
                style={{
                  background: '#F0D474',
                  padding: '7px 5rem',
                  color: '#3B638C',
                }}
                onClick={() => {
                  if (postSurveyNeedToComplete) {
                    setIsModalSurveyOpen(true);
                  } else {
                    setIsModalOpen(true);
                  }
                }}
              >
                Completion Certificate
              </button>
            </div>

            <div className="shadow-sm" style={{ height: '4rem' }}></div>
          </div>
        ) : null}
      </div>

      {/* <div className="modules-header">
        <h1>Modules</h1>
      </div>
      <div className="modules-container">
        {modules.length
          ? modules
              .sort((a, b) => a.id - b.id)
              .map((module, idx) => {
                return (
                  <div
                    className="card"
                    style={{
                      borderRadius: '20px',
                      backgroundImage: `url(${moduleImages[idx]})`,
                      border: 'none',
                    }}
                    key={idx}
                  >
                    <div className="card-overlay">
                      <div className="card-top" style={{ cursor: 'pointer' }}>
                        <span
                          className="ask-expert"
                          onClick={() =>
                            navigate(`/ask-the-expert?module=${idx}`)
                          }
                        >
                          Ask the experts
                          <img
                            className="icon-ask ml2 mb-1 ms-2"
                            alt="icon"
                            src={iconAsk}
                          />
                        </span>
                      </div>
                      <div className="card-middle px-3 pb-2 pt-0">
                        <img
                          className="book-icon"
                          alt="Book Icon"
                          src={iconBook}
                        />
                        <div className="card-info">
                          <div className="module">Module {idx}</div>
                          <div className="title" style={{ minHeight: '80px' }}>
                            {module.description}
                          </div>
                          {idx < 7 ? (
                            renderDoctorInfo(idx)
                          ) : (
                            <>
                              {showm7 && idx == 7 ? (
                                <div>
                                  {m7Doctors.map((doctor, id) => {
                                    return (
                                      <div key={id} className="mt-2">
                                        <div className="instructor">
                                          {doctor.title} {doctor.name}
                                        </div>
                                        <div className="position">
                                          {doctor.speciality}
                                        </div>
                                        <div className="position">
                                          {doctor.sector}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div
                                    className="see-more-btn"
                                    onClick={() => showMoreHander(idx, 'close')}
                                  >
                                    Read Less
                                  </div>
                                </div>
                              ) : !showm7 && idx == 7 ? (
                                <>
                                  <div className="instructor">
                                    {m7Doctors[0]?.title} {m7Doctors[0]?.name}
                                  </div>
                                  <div className="position">
                                    {m7Doctors[0]?.speciality}
                                  </div>
                                  <div className="position">
                                    {m7Doctors[0]?.sector}
                                  </div>

                                  <div
                                    className="see-more-btn"
                                    onClick={() => showMoreHander(idx, 'open')}
                                  >
                                    Read More
                                  </div>
                                </>
                              ) : null}

                              {showm8 && idx == 8 ? (
                                <div>
                                  {m8Doctors.map((doctor, id) => {
                                    return (
                                      <div key={id} className="mt-2">
                                        <div className="instructor">
                                          {doctor.title} {doctor.name}
                                        </div>
                                        <div className="position">
                                          {doctor.speciality}
                                        </div>
                                        <div className="position">
                                          {doctor.sector}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div
                                    className="see-more-btn"
                                    onClick={() => showMoreHander(idx, 'close')}
                                  >
                                    Read Less
                                  </div>
                                </div>
                              ) : !showm8 && idx == 8 ? (
                                <>
                                  <div className="instructor">
                                    {m8Doctors[0]?.title} {m8Doctors[0]?.name}
                                  </div>
                                  <div className="position">
                                    {m8Doctors[0]?.speciality}
                                  </div>
                                  <div className="position">
                                    {m8Doctors[0]?.sector}
                                  </div>

                                  <div
                                    className="see-more-btn"
                                    onClick={() => showMoreHander(idx, 'open')}
                                  >
                                    Read More
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="card-bottom">
                        <button
                          className="enter-btn"
                          onClick={() => {
                            navigate(`/video?module=${idx}`, { state: module });
                            window.location.href = `/video?module=${idx}`;
                          }}
                        >
                          Enter
                          <img
                            src={iconEnter}
                            className="enter-icon mb-1 ms-2"
                            alt="icon"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
          : null}
      </div> */}
    </div>
  );
};

export default Modules;
