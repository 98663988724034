import React from 'react';
import Header from '../components/header';
import dlpLogo from '../assets/dlp_logo.png';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import overlayImg from '../assets/overlayImage.png';
import axios from 'axios';
import { message } from 'antd';
import SuccessRegister from '../components/SuccessRegister';
// import surveyIcon from '../images/survey-icon.png';
import TakeSurveyModal from '../components/TakeSurveyModal';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState(
    location.state?.data
      ? location.state?.data
      : {
          title: '',
          name: '',
          ic_number: '',
          phone_number: '',
          email: '',
          mmc_number: '',
          myCpd_number: '',
          mps_number: '',
          speciality: '',
          code: '',
          sector: '',
          place_of_practice: '',
          postcode: '',
          state: '',
          current_t2d_a_week: '',
          otherSpeciality: '',
          otherSector: '',
          current_where_did_you_hear: '',
          otherWhereDidYouHear: '',
        }
  );

  const [submitted, setSubmitted] = useState(false);
  const [inputError, setInputError] = useState({
    name: null,
    number: null,
    email: null,
  });

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSurveyOpen] = useState(false);
  const [status, setStatus] = useState('');
  // console.log('data', data);

  const handleChange = (e) => {
    let getData = e.target.value;

    if (
      e.target.name === 'phone_number' ||
      e.target.name === 'ic_number' ||
      e.target.name === 'postcode'
    ) {
      getData = e.target.value.replace(/\D/g, '');
    }

    const errorMessage = getData.trim() === '' ? 'This field is required' : '';
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: errorMessage,
    }));

    setData({ ...data, [e.target.name]: getData });
    if (e.target.name === 'name') {
      const nameRegex = /^[a-zA-Z ]*$/;
      if (!nameRegex.test(e.target.value)) {
        setInputError({
          ...inputError,
          name: 'error',
        });
      } else {
        setInputError({
          ...inputError,
          name: null,
        });
      }
    } else if (e.target.name === 'number') {
      const numberRegex = /^(01)[0-46-9]*[0-9]{8,9}$/;
      if (!numberRegex.test(e.target.value)) {
        setInputError({
          ...inputError,
          number: 'error',
        });
      } else {
        setInputError({
          ...inputError,
          number: null,
        });
      }
    }
    setSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    const newData = { ...data };
    // console.log('data', data);
    try {
      if (newData.otherSector) {
        newData.sector = newData.otherSector;
      }

      if (newData.otherSpeciality) {
        newData.speciality = newData.otherSpeciality;
      }

      if (newData.otherWhereDidYouHear) {
        newData.current_where_did_you_hear = newData.otherWhereDidYouHear;
      }

      const response = await axios.post('/api/auth/register', newData);
      // console.log(response.data);
      if (response) {
        setSubmitted(false);
        setIsModalOpen(true);
        setStatus('success');
      }
    } catch (error) {
      setSubmitted(false);
      if (error.response?.data.error === 'You have already registered.') {
        setIsModalOpen(true);
        setStatus('joined before');
      } else {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    }
  };

  return (
    <div
      style={{
        background: 'linear-gradient(180deg, #C6D2DD 0%, #4A6F94 100%)',
      }}
    >
      <Header />
      <TakeSurveyModal
        isModalSurveyOpen={isModalSurveyOpen}
        navigate={navigate}
        description={
          <div>
            You are required to complete the survey
            <div>in order to proceed to create an account</div>
          </div>
        }
        action={() => navigate('/survey/pre/one', { state: { data } })}
      />

      <SuccessRegister
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        statusChecker={status}
      />

      <div className="register-container">
        <div className="left-part-container">
          <div className="auth-image mt-3">
            <div className="text-start ms-5">
              <img
                src={dlpLogo}
                style={{ width: 'auto', height: '170px' }}
                alt="logo"
              />
            </div>
            <div className="mt-4 d-flex">
              <img
                src={overlayImg}
                alt="img"
                style={{ width: '100%', height: '400px', objectFit: 'contain' }}
              />
            </div>
          </div>
        </div>

        <div className="show-logo-in-mobile  mt-3">
          <img src={dlpLogo} style={{ width: '50%' }} alt="logo" />
        </div>

        <div className="right-container">
          <div className="welcome-text">Welcome to</div>
          <div className="welcome-text">Diabetes Lifestyle Programme</div>
          <div className="create-acc-text py-2">Create an account</div>

          <div className="text-start text-muted">
            This is a one-time registration only. The information collected is
            for the purpose of CPD points redemption and electronic direct mails
            (eDM) updates on DLP activities.
          </div>
          <form className="register-form mt-3" onSubmit={handleSubmit}>
            <label className="register-label" htmlFor="name">
              Title*
            </label>
            <select
              name="title"
              id="selectList"
              onChange={handleChange}
              className={`register-input ${
                submitted && data.ic_number === '' ? 'invalid-input' : ''
              }`}
              required
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="Ms">Ms</option>
              <option value="Mrs">Mrs</option>
              <option value="Mr">Mr</option>
              <option value="Dr">Dr</option>
              <option value="Datuk">Datuk</option>
              <option value="Dato">Dato</option>
              <option value="Datin">Datin</option>
              <option value="Professor">Professor</option>
              <option value="Assistant Professor">Assistant Professor</option>
              <option value="Associate Professor">Associate Professor</option>
            </select>
            {submitted && !data.title && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="NRIC">
              Full Name (as per NRIC)*
            </label>
            <input
              className="register-input"
              type="name"
              id="name"
              name="name"
              placeholder="Enter your text"
              required
              value={data.name}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            />
            {submitted && !data.name && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="ic">
              IC/Passport No.*
            </label>
            <input
              className={`register-input ${
                data.ic_number === '' ? 'invalid-input' : ''
              }`}
              type="text"
              id="ic"
              name="ic_number"
              placeholder="Enter your text"
              required
              value={data.ic_number}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            />
            <div style={{ marginTop: '-0.8rem' }}>
              <small> (eg: 981234XXXXXX/A00000000) </small>
            </div>
            {submitted && !data.ic_number && (
              <span className="error mt-1">{'Required input'}</span>
            )}
            <label className="register-label mt-2" htmlFor="number">
              Phone Number*
            </label>
            <input
              className="register-input"
              type="text"
              id="number"
              name="phone_number"
              placeholder="Enter your text"
              required
              value={data.phone_number}
              onChange={handleChange}
              pattern="^(?:\+?6?01)[0-9]{8,9}$"
              style={{ backgroundColor: '#E3EFFA' }}
            />
            <div style={{ marginTop: '-0.8rem' }}>
              <small> (eg: 011xxxxxxxx)</small>
            </div>
            {submitted && !data.phone_number && (
              <span className="error mt-1">{'Required input'}</span>
            )}
            <label className="register-label mt-2" htmlFor="email">
              Email Address*
            </label>
            <input
              className="register-input"
              type="email"
              id="email"
              name="email"
              placeholder="Enter your text"
              required
              value={data.email}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            />
            {submitted && !data.email && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="code">
              CPD claim: *
            </label>
            <select
              name="code"
              id="code"
              className="register-input"
              required
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="mma">MMA</option>
              <option value="mps">MPS</option>
              <option value="mycpd">MyCPD</option>
              <option value="none">Not applicable</option>
            </select>
            {submitted && !data.code && (
              <span className="error ">{'Required input'}</span>
            )}
            <br />
            {data.code === 'mma' && (
              <>
                <label className="register-label" htmlFor="mmc">
                  Professional Registration Number (eg MMC number, RPh, please
                  type N/A if not applicable): *
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="mmc"
                  name="mmc_number"
                  placeholder="Enter your text"
                  required
                  value={data.mmc_number}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.mmc_number && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}
            {data.code === 'mps' && (
              <>
                <label className="register-label" htmlFor="mps">
                  Professional Registration Number (eg MMC number, RPh, please
                  type N/A if not applicable): *
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="mps"
                  name="mps_number"
                  placeholder="Enter your text"
                  required
                  value={data.mps_number}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.mps_number && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}
            {data.code === 'mycpd' && (
              <>
                <label className="register-label" htmlFor="mycpd">
                  Professional Registration Number (eg MMC number, RPh, please
                  type N/A if not applicable): *
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="mycpd"
                  name="myCpd_number"
                  placeholder="Enter your text"
                  required
                  value={data.myCpd_number}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.myCpd_number && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}
            <label
              className="register-label"
              style={{ marginTop: '-1rem' }}
              htmlFor="speciality"
            >
              Speciality*
            </label>
            <select
              name="speciality"
              id="speciality"
              className="register-input"
              required
              value={data.speciality}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="Family Medicine Specialist">
                Family Medicine Specialist
              </option>
              <option value="Family Medicine Trainee">
                Trainee in Family Medicine
              </option>
              <option value="Medical Officer">Medical Officer</option>
              <option value="General Practitioner">General Practitioner</option>
              <option value="Internal medicine">Internal medicine</option>
              <option value="Endocrinologist">Endocrinologist</option>
              <option value="Endocrinology Trainee">
                Trainee in Endocrinology
              </option>
              <option value="Dietitian">Dietitian</option>
              <option value="Nutritionist">Nutritionist</option>
              <option value="Diabetes Nurse">Diabetes Nurse</option>
              <option value="Nurse">Nurse (any department)</option>
              <option value="Pharmacist">Pharmacist</option>
              <option value="Other Medical Specialist">
                Other Medical Specialist (Please state)
              </option>
              <option value="Others">Others (Please state)</option>
            </select>
            {submitted && !data.speciality && (
              <span className="error ">{'Required input'}</span>
            )}
            {(data.speciality === 'Others' ||
              data.speciality === 'Other Medical Specialist' ||
              data.speciality === 'Nurse') && (
              <>
                <label className="register-label" htmlFor="otherSpeciality">
                  Other Speciality*
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="otherSpeciality"
                  name="otherSpeciality"
                  placeholder="Enter your text"
                  required
                  value={data.otherSpeciality}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.otherSpeciality && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}
            <label className="register-label" htmlFor="sector">
              Sector*
            </label>
            <select
              name="sector"
              id="sector"
              className="register-input"
              required
              value={data.sector}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="MoH hospital">MoH hospital</option>
              <option value="MoH clinic">MoH clinic</option>
              <option value="Private hospital">Private hospital</option>
              <option value="Private clinic">Private clinic</option>
              <option value="Govt university/teaching hospital">
                Govt university/teaching hospital
              </option>
              <option value="Private university/teaching hospital">
                Private university/teaching hospital
              </option>
              <option value="Other govt sectors">Other govt sectors</option>
              <option value="Other private sectors">
                Other private sectors
              </option>
            </select>
            {submitted && !data.sector && (
              <span className="error ">{'Required input'}</span>
            )}
            {(data.sector === 'Other govt sectors' ||
              data.sector === 'Other private sectors') && (
              <>
                <label className="register-label" htmlFor="otherSector">
                  Other Sector*
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="otherSector"
                  name="otherSector"
                  placeholder="Enter your text"
                  required
                  value={data.otherSector}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.otherSector && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}
            <label className="register-label" htmlFor="state">
              State*
            </label>
            <select
              name="state"
              id="state"
              className="register-input"
              required
              value={data.state}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="Johor">Johor</option>
              <option value="Kedah">Kedah</option>
              <option value="Kelantan">Kelantan</option>
              <option value="Kuala Lumpur">Kuala Lumpur</option>
              <option value="Labuan">Labuan</option>
              <option value="Melaka">Melaka</option>
              <option value="Negeri Sembilan">Negeri Sembilan</option>
              <option value="Pahang">Pahang</option>
              <option value="Penang">Penang</option>
              <option value="Perak">Perak</option>
              <option value="Perlis">Perlis</option>
              <option value="Putrajaya">Putrajaya</option>
              <option value="Sabah">Sabah</option>
              <option value="Sarawak">Sarawak</option>
              <option value="Selangor">Selangor</option>
              <option value="Terengganu">Terengganu</option>
            </select>
            {submitted && !data.state && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="place">
              Place of practice (write in full name and in CAPS):*
            </label>
            <input
              required
              className="register-input"
              type="text"
              id="place"
              name="place_of_practice"
              placeholder="Enter your text"
              value={data.place_of_practice.toUpperCase()}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            />
            {submitted && !data.place_of_practice && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="postcode">
              Postcode*
            </label>
            <input
              required
              className="register-input"
              type="text"
              id="postcode"
              name="postcode"
              placeholder="Enter your text"
              value={data.postcode}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            />
            {submitted && !data.postcode && (
              <span className="error ">{'Required input'}</span>
            )}
            <label className="register-label" htmlFor="diabetes">
              How many Type 2 diabetes (T2D) patients (not on insulin) do you
              currently see every week?*
            </label>
            <select
              name="current_t2d_a_week"
              id="diabetes"
              className="register-input"
              required
              value={data.current_t2d_a_week}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="1 to 25">1 to 25</option>
              <option value="26 to 50">26 to 50</option>
              <option value="51 to 100">51 to 100</option>
              <option value="more than 100">more than 100</option>
              <option value="I do not treat T2DM patients">
                I do not treat T2DM patients
              </option>
            </select>
            {submitted && !data.current_t2d_a_week && (
              <span className="error ">{'Required input'}</span>
            )}

            <label className="register-label" htmlFor="onlineCourses">
              Where did you hear about this Online Course?*
            </label>
            <select
              name="current_where_did_you_hear"
              id="onlineCourses"
              className="register-input"
              required
              value={data.current_where_did_you_hear}
              onChange={handleChange}
              style={{ backgroundColor: '#E3EFFA' }}
            >
              <option value="">-Select-</option>
              <option value="Email from the Diabetes Lifestyle Programme (DLP)">
                Email from the Diabetes Lifestyle Programme (DLP)
              </option>
              <option value="Diabetes Lifestyle Programme (DLP) Webinars">
                Diabetes Lifestyle Programme (DLP) Webinars
              </option>
              <option value="Malaysian Endocrine and Metabolic Society (MEMS)">
                Malaysian Endocrine and Metabolic Society (MEMS)
              </option>
              <option value="Malaysian Medical Association (MMA)">
                Malaysian Medical Association (MMA)
              </option>
              <option value="Malaysian Family Medicine Specialists’ Association (FMSA)">
                Malaysian Family Medicine Specialists’ Association (FMSA)
              </option>
              <option value="Academy of Family Physicians of Malaysia (AFPM)">
                Academy of Family Physicians of Malaysia (AFPM)
              </option>
              <option value="Malaysian Dietitians’ Association (MDA)">
                Malaysian Dietitians’ Association (MDA)
              </option>
              <option value="Malaysian Diabetes Educators Society (MDES)">
                Malaysian Diabetes Educators Society (MDES)
              </option>
              <option value="Malaysian Pharmacists Society (MPS)">
                Malaysian Pharmacists Society (MPS)
              </option>
              <option value="Doctor in my workplace">
                Doctor in my workplace
              </option>
              <option value="Colleagues in my workplace">
                Colleagues in my workplace
              </option>
              <option value="Others: (Please state)">
                Others: (Please state)
              </option>
            </select>

            {submitted && !data.current_where_did_you_hear && (
              <span className="error ">{'Required input'}</span>
            )}

            {data.current_where_did_you_hear === 'Others: (Please state)' && (
              <>
                <label
                  className="register-label"
                  htmlFor="otherWhereDidYouHear"
                >
                  Others: Please specify where did you hear about this Online
                  Course?*
                </label>
                <input
                  className="register-input"
                  type="text"
                  id="otherWhereDidYouHear"
                  name="otherWhereDidYouHear"
                  placeholder="Enter your text"
                  required
                  value={data.otherWhereDidYouHear}
                  onChange={handleChange}
                  style={{ backgroundColor: '#E3EFFA' }}
                />
                {submitted && !data.otherWhereDidYouHear && (
                  <span className="error ">{'Required input'}</span>
                )}
                <br />
              </>
            )}

            <p className="text-center">*Note: Mandatory Fields</p>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#F0D474',
                textAlign: 'left',
                textDecoration: 'underline',
              }}
            >
              *By submitting this application, I understand/confirm that:
            </div>
            <div className="checkbox-container" style={{ marginTop: '15px' }}>
              <label className="py-1">
                <input type="checkbox" name="checkbox1" required /> I understand
                that CPD points are awarded for the completion{' '}
                <strong>(ie watched video & answered 10 test questions)</strong>{' '}
                of selected modules that have been identified within the opening
                period (unless notified otherwise). *
              </label>
              <label className="py-1">
                I confirm that my personal data above is correct and understand
                that any errors in the above filled-in personal details may
                disqualify me from CPD points redemption. *
              </label>
              <label className="py-1">
                I acknowledge that my details above will be used for CPD
                submissions, internal record & reporting only. *
              </label>
              <label>
                I agree to allow the DLP Secretariat to contact me via email,
                SMS & WhatsApp. *
              </label>
            </div>
            <div onClick={() => setSubmitted(true)}>
              {submitted ? (
                <button disable={submitted} className="register-btn">
                  Loading ...
                </button>
              ) : (
                <button
                  disable={submitted}
                  className="register-btn"
                  type="submit"
                >
                  Create an account
                </button>
              )}
            </div>
          </form>
          <p className="already-text text-center">
            Already have an account?{' '}
            <strong
              style={{ color: '#F0D474', cursor: 'pointer' }}
              onClick={() => navigate('/login')}
            >
              Login Now
            </strong>
          </p>

          <div className="show-textbox-in-mobile">
            <img
              className="mt-5"
              src={overlayImg}
              alt="img"
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
